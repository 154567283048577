import { post, get, ResponseType, URL } from "@/utils/axios";

// 树形获取广告配置项目
export const treeAdConfigApi = (params: {
  pageNo: number,
  pageSize: number,
}) => get(URL.AD_TREE_ALL, { params })
// 复制
export const copyAdConfigApi = (id: number | number[]) => {
  return post(URL.AD_CONFIG_COPY, { data: { id } })
}
// 删除
export const delAdConfigApi = (id: number | number[]) => {
  return post(URL.AD_CONFIG_DEL, { data: { id } })
}

export const delAdPositionApi = (id: number | number[]) => {
  return post(URL.AD_POSITION_DEL, { data: { id } })
}

export const delAdItemApi = (id: number | number[]) => {
  return post(URL.AD_ITEM_DEL, { data: { id } })
}

// 获取配置项
export const adConfigPageApi = () => {
  return get(URL.AD_LIST_ENUM)
}


// 新增 写三遍。。。😅
export const adAddApi = (data: object, level: number) => {
  const temp = [
    URL.AD_CONFIG_ADD,
    URL.AD_POSITION_ADD,
    URL.AD_ITEM_ADD
  ]
  return post(temp[level - 1], { data })
}


// 编辑 写三遍。。。😅
export const adEditApi = (data: object, level: number) => {
  const temp = [
    URL.AD_CONFIG_MODIFY,
    URL.AD_POSITION_UPDATE,
    URL.AD_ITEM_UPDATE
  ]
  return post(temp[level - 1], { data })
}
